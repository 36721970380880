import { useEffect } from 'react';

import { useVersionStore } from '@/stores';

const VERSION_FILE_PATH = '/version';
const INTERVAL_MS = 5 * 60 * 1000; // 5 min

export const useWatchVersion = () => {
  const { setVersion } = useVersionStore();

  const fetchVersionText = () => {
    fetch(VERSION_FILE_PATH, { cache: 'no-store' })
      .then((res) => res.text())
      .then((text) => setVersion(text.trim()))
      .catch(() => {
        // NOTE: アプリケーション自体に影響を出さないよう、エラー時はなにもしない
      });
  };

  useEffect(() => {
    fetchVersionText();
    const timer = setInterval(fetchVersionText, INTERVAL_MS);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
