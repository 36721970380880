import { createElement } from 'react';

import { useAdminMode } from '@/hooks/utils';

type Props = {
  defaultComponent: (props: JSX.IntrinsicAttributes) => JSX.Element;
  adminComponent: (props: JSX.IntrinsicAttributes) => JSX.Element;
};

export const useSwitchAdminPage = ({
  defaultComponent,
  adminComponent,
}: Props) => {
  const { isAdminMode, isLoading } = useAdminMode();

  if (isLoading) return { componentElement: null };

  const componentElement = (() => {
    return createElement(isAdminMode ? adminComponent : defaultComponent);
  })();
  return { componentElement };
};
