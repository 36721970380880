import { useEffect } from 'react';

// NOTE: https://github.com/facebook/react/issues/11538#issuecomment-417504600
export const usePatchDOMForGoogleTranslate = () => {
  useEffect(() => {
    if (typeof Node === 'function' && Node.prototype) {
      const originalRemoveChild = Node.prototype.removeChild;
      Node.prototype.removeChild = function <T extends Node>(
        this: Node,
        child: T
      ): T {
        if (child.parentNode !== this) {
          if (console) {
            console.error(
              'Cannot remove a child from a different parent',
              child,
              this
            );
          }
          return child;
        }
        return originalRemoveChild.apply(this, [child]) as T;
      };

      const originalInsertBefore = Node.prototype.insertBefore;
      Node.prototype.insertBefore = function <T extends Node>(
        this: Node,
        newNode: T,
        referenceNode: Node | null
      ): T {
        if (referenceNode && referenceNode.parentNode !== this) {
          if (console) {
            console.error(
              'Cannot insert before a reference node from a different parent',
              referenceNode,
              this
            );
          }
          return newNode;
        }
        return originalInsertBefore.apply(this, [newNode, referenceNode]) as T;
      };
    }
  }, []);
};
