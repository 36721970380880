import { useEffect, useState } from 'react';

export const useSanitizedHtml = (rawHtml: string) => {
  const [sanitizedHtml, setSanitizedHtml] = useState<string>();

  useEffect(() => {
    import('sanitize-html')
      .then((module) =>
        module.default(rawHtml, {
          allowedTags: ['a'],
          allowedAttributes: {
            a: ['href', 'class', 'target', 'rel'],
          },
          allowedClasses: { '*': ['tw-*', 'hover:tw-*'] },
        })
      )
      .then(setSanitizedHtml);
  }, [rawHtml]);

  return sanitizedHtml;
};
