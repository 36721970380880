import { ApolloProvider } from '@apollo/react-hooks';
import { ChakraProvider } from '@chakra-ui/react';
import { RecoilRoot } from 'recoil';

import type { AppProps } from 'next/app';

import { useApollo } from '@/libs/apollo';
import { theme } from '@/libs/chakra-ui';

type AppProviderProps = {
  pageProps?: AppProps['pageProps'];
  children: React.ReactNode;
};

export const AppProvider = ({ children, pageProps }: AppProviderProps) => {
  const apolloClient = useApollo(pageProps);

  return (
    <RecoilRoot>
      <ApolloProvider client={apolloClient}>
        <ChakraProvider theme={theme}>{children}</ChakraProvider>
      </ApolloProvider>
    </RecoilRoot>
  );
};
