export const DATE_FORMAT = {
  API: 'yyyy-MM-dd',
  HHmmss: 'HH:mm:ss',
  yyyyMMdd: 'yyyy/MM/dd',
  yyyyMMddHHmm: 'yyyy/MM/dd HH:mm',
  yyyyMd_jp: 'yyyy年M月d日',
  yyyyMdeee_jp: 'yyyy年M月d日（eee）',
  yyyyMdeeeHH_jp: 'yyyy年M月d日（eee）HH時',
  yyyyMdeeeHHmm_jp: 'yyyy年M月d日（eee）HH時mm分',
};
