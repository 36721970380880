import { flatten, unflatten } from 'flat';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import type { ParsedUrlQuery } from 'querystring';

import { parseToPositiveInt } from '@/utils/parse';

type UsePaginationProps = {
  prefix?: string;
  defaultPage?: number;
  defaultPerPage?: number;
};

export const usePagination = ({
  prefix,
  defaultPage = 1,
  defaultPerPage = 10,
}: UsePaginationProps) => {
  const router = useRouter();

  const addPrefix = (key: string) => (prefix ? `${prefix}.${key}` : key);

  const page = parseToPositiveInt(router.query[addPrefix('page')], defaultPage);
  const perPage = parseToPositiveInt(
    router.query[addPrefix('perPage')],
    defaultPerPage
  );

  const update = useCallback(
    (pathParams: { page: number; perPage: number }) => {
      const params = prefix ? { [prefix]: pathParams } : pathParams;
      router.replace(
        {
          query: flatten({
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...unflatten<ParsedUrlQuery, any>(router.query),
            ...params,
          }),
        },
        undefined,
        { shallow: true }
      );
    },
    [prefix, router]
  );

  return {
    page,
    perPage,
    update,
  };
};
