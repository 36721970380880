import { DATE_FORMAT } from '@/constants/date';
import { z } from '@/libs/zod';
import { formatDate, isValidDate } from '@/utils/date';

export const parseToString = <T>(target: unknown, defaultValue: T) => {
  const parseResult = z.string().safeParse(target);
  return parseResult.success ? parseResult.data : defaultValue;
};

export const parseToInt = <T>(target: unknown, defaultValue: T) => {
  const parseResult = z.coerce.number().int().safeParse(target);
  return parseResult.success ? parseResult.data : defaultValue;
};

export const parseToPositiveInt = <T>(target: unknown, defaultValue: T) => {
  const parseResult = z.coerce.number().int().positive().safeParse(target);
  return parseResult.success ? parseResult.data : defaultValue;
};

export const parseToIntArray = <T>(target: unknown, defaultValue: T) => {
  const parseResult = z.array(z.number().int()).safeParse(target);
  return parseResult.success ? parseResult.data : defaultValue;
};

export const parseToStringArray = <T>(target: unknown, defaultValue: T) => {
  const parseResult = z.array(z.string()).safeParse(target);
  return parseResult.success ? parseResult.data : defaultValue;
};

export const parseToBoolean = <T>(target: unknown, defaultValue: T) => {
  const convertedTarget =
    target === 'true' ? true : target === 'false' ? false : target;
  const parseResult = z.boolean().safeParse(convertedTarget);
  return parseResult.success ? parseResult.data : defaultValue;
};

const dateRangeSchema = z.object({
  from: z.string().optional(),
  to: z.string().optional(),
});

export const parseToDateRange = (
  target: unknown,
  defaultValue: { from?: string; to?: string },
  template = DATE_FORMAT.yyyyMMdd
) => {
  const parseResult = dateRangeSchema.safeParse(target);
  return parseResult.success
    ? {
        from:
          parseResult.data.from != null && isValidDate(parseResult.data.from)
            ? formatDate(parseResult.data.from, template)
            : defaultValue.from,
        to:
          parseResult.data.to != null && isValidDate(parseResult.data.to)
            ? formatDate(parseResult.data.to, template)
            : defaultValue.to,
      }
    : defaultValue;
};
