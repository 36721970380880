import { useToast, type UseToastOptions } from '@chakra-ui/react';
import { useCallback } from 'react';

export const useNotice = () => {
  const toast = useToast();

  const showToast = useCallback(
    (
      option: Pick<
        UseToastOptions,
        'id' | 'status' | 'title' | 'description' | 'duration'
      >
    ) =>
      toast({
        duration: 9000,
        isClosable: true,
        position: 'top',
        ...option,
      }),
    [toast]
  );

  return {
    showToast,
  };
};
