import '@/styles/reset.css';
import '@/styles/globals.css';

import 'react-datepicker/dist/react-datepicker.css';
import '@/styles/react-datepicker.css';

import '@react-pdf-viewer/core/lib/styles/index.css';

import '@/polyfills/arrayAt';
import 'core-js/features/string/replace-all';

import { Noto_Sans_JP } from '@next/font/google';
import Head from 'next/head';

import type { AppProps } from 'next/app';

import { useInitTagManager } from '@/hooks/utils';
import { usePatchDOMForGoogleTranslate } from '@/hooks/utils/usePatchDOMForGoogleTranslate';
import { AppProvider } from '@/providers/app';

const notoSansJapanese = Noto_Sans_JP({
  weight: ['400', '500', '700'],
  preload: false,
});

function MyApp({ Component, pageProps }: AppProps) {
  usePatchDOMForGoogleTranslate(); // NOTE: https://github.com/facebook/react/issues/11538

  useInitTagManager({ gtmId: process.env.NEXT_PUBLIC_GTM_ID ?? '' });

  return (
    <AppProvider pageProps={pageProps}>
      <style jsx global>
        {`
          // Chakuraのコンポートでスタイルを充てるため
          :root {
            --font-noto-sans-jp: ${notoSansJapanese.style.fontFamily};
          }
        `}
      </style>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1.0,maximum-scale=1.0"
        />
      </Head>
      <div className={notoSansJapanese.className}>
        <Component {...pageProps} />
      </div>
    </AppProvider>
  );
}

export default MyApp;
