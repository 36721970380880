export const LOCAL_STORAGE_KEY = {
  INVISIBLE_COLUMN_KEYS: {
    adminContest: () => 'admin_contest_table_invisible_columns_contest',
  },
  COLUMN_SIZES: {
    adminContestList: 'admin_contest_table_column_size',
  },
  SKIP_OPEN_SCREENING_HOW_TO_MODAL: 'skip_open_screening_how_to_modal',
  DISPLAY_MODE: 'display_mode',
  ADMIN_CONTEST_LIST_TOOLBAR: 'admin_contest_list_toolbar',
  ADMIN_CONTEST_LIST_TABLE: 'admin_contest_list_table',
};
