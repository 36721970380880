import { useCallback } from 'react';

export const useGenerateOptimizedImages = () => {
  /**
   * srcset属性に設定されているすべての画像リンクを取得する
   * @param {string} srcsetAttribute srcset属性の値
   * @returns {string[]} srcset属性に設定されているリンク
   */
  const parseSrcsetToImageLinks = (srcsetAttribute: string) => {
    const urlPattern = /^https?:\/\/[\w/:%#\$&\?\(\)~\.=\+\-]+$/;

    /**
     * srcset のフォーマットに基づいてパース
     * https://developer.mozilla.org/ja/docs/Web/API/HTMLImageElement/srcset
     */
    return srcsetAttribute
      .replaceAll(',', ' ')
      .split(' ')
      .filter((splittedValue) => urlPattern.test(splittedValue));
  };

  /**
   * srcset属性に設定されているURL全てにGETリクエストを送り、最適化された画像を生成させる
   * @param {HTMLImageElement} image DOM要素
   */
  const generate = useCallback((image: HTMLImageElement) => {
    const optimizedImageLinks = parseSrcsetToImageLinks(
      image.getAttribute('srcset') ?? ''
    );
    Promise.all(
      optimizedImageLinks.flatMap((imageLink) => {
        /**
         * Note:
         * ブラウザによって取得しようとする画像の拡張子が異なるため、
         * それぞれのブラウザで取得する拡張子をもつ画像を生成する。
         * 生成対象の拡張子はファイルサイズがなるべく軽いものを対象とする
         * https://developer.mozilla.org/ja/docs/Web/HTTP/Content_negotiation/List_of_default_Accept_values#%E7%94%BB%E5%83%8F%E3%81%AE%E5%80%A4
         */
        const generatedImageExtension = ['image/avif', 'image/webp'];

        return generatedImageExtension.map((ext) =>
          fetch(imageLink, {
            headers: {
              accept: ext,
            },
          })
        );
      })
    );
  }, []);

  return { generate };
};
