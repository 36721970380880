import { useQuery } from '@apollo/client';

import { UseUserDocument } from '@/graphql/generated.anonymous';

export function useUser() {
  // NOTE: role判定をjwtに一任するためにuseQueryWrapperを敢えて使わない
  const { data, error, loading } = useQuery(UseUserDocument);

  const user = data?.me;

  return {
    isLoggedIn: !!user?.id,
    user: user?.id ? user : null,
    isKgAdmin: user?.allowedRoles.some((r) => r.name === 'kg-admin'),
    isKgInformer: user?.allowedRoles.some((r) => r.name === 'kg-informer'),
    isOrgAdmin: user?.allowedRoles.some((r) => r.name === 'org-admin'),
    isJuror: user?.allowedRoles.some((r) => r.name === 'juror'),
    error,
    isLoading: loading,
  };
}
