import { useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';

type VersionState = {
  current: string;
  next: string;
};

export const versionState = atom<VersionState | undefined>({
  key: 'version',
  default: undefined,
});

export const useVersionStore = () => {
  const [state, setState] = useRecoilState(versionState);
  const requiresRefresh = useMemo(
    () => state && state.current !== state.next,
    [state]
  );

  const setVersion = (next: string) => {
    setState((prev) => {
      if (!prev) {
        return { current: next, next: next };
      } else {
        return { ...prev, next };
      }
    });
  };

  return { requiresRefresh, setVersion };
};
