import { useRouter } from 'next/router';
import { useMemo, useCallback } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { ROUTE } from '@/constants/route';
import { LOCAL_STORAGE_KEY } from '@/constants/storage';
import { useUser } from '@/hooks/features/auth';

export type DisplayMode = 'admin' | 'organization';

export const useAdminMode = () => {
  const { isKgAdmin, isKgInformer, isLoading } = useUser();

  const [displayMode, setValue] = useLocalStorage<DisplayMode | undefined>(
    LOCAL_STORAGE_KEY.DISPLAY_MODE,
    undefined
  );

  const router = useRouter();

  const isAdminMode = useMemo(() => {
    if (!isKgAdmin && !isKgInformer) return false;
    if (!displayMode) return true;
    return displayMode === 'admin';
  }, [isKgAdmin, isKgInformer, displayMode]);

  const toggleMode = useCallback(() => {
    setValue(isAdminMode ? 'organization' : 'admin');
    if (isAdminMode) router.push(ROUTE.top());
  }, [isAdminMode, router, setValue]);

  return { isAdminMode, isLoading, toggleMode, setValue };
};
