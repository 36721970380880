import TagManager from 'react-gtm-module';

import { useIsomorphicLayoutEffect } from '@/hooks/utils';

type Props = {
  gtmId: string;
};

export type CustomDataLayer = {
  contest: {
    id: number;
    mainCategory: string;
    subCategory: string;
  } | null;
  article: {
    id: number;
  } | null;
};

export function useInitTagManager({ gtmId }: Props) {
  const initialDataLayer: CustomDataLayer = {
    contest: null,
    article: null,
  };
  useIsomorphicLayoutEffect(() => {
    TagManager.initialize({ gtmId, dataLayer: initialDataLayer });
  }, []);

  useIsomorphicLayoutEffect(() => {
    document.body.classList?.remove('loading');
  }, []);
}
