import Axios from 'axios';
import { useState, useCallback } from 'react';

const axios = Axios.create();

type Props = {
  url: string;
  file: File;
  onSuccess?: () => void;
  onError?: () => void;
};

export const useUploadFile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const execute = useCallback(({ url, file, onSuccess, onError }: Props) => {
    setLoading(true);
    axios
      .put(url, file, {
        headers: { 'Content-Type': file.type },
      })
      .then(() => onSuccess && onSuccess())
      .catch((err) => {
        setError(err);
        onError && onError();
      })
      .finally(() => setLoading(false));
  }, []);

  return { execute, loading, error };
};
