import { useMemo, useCallback } from 'react';

import type { SortCondition } from '@/types/ui';

type UseSortConditionProps<T> = {
  targetColumnId: T;
  sortCondition?: SortCondition<T>;
  onChangeSortCondition: (condition: SortCondition<T> | undefined) => void;
};

export const useSortCondition = <T>({
  targetColumnId,
  sortCondition,
  onChangeSortCondition,
}: UseSortConditionProps<T>): {
  sortDirection: 'asc' | 'desc' | false;
  handleChangeSortCondition: () => void;
} => {
  const isActivatedSort = sortCondition?.columnId === targetColumnId;

  const sortDirection = useMemo(
    () => (isActivatedSort ? sortCondition.direction : false),
    [isActivatedSort, sortCondition?.direction]
  );

  const handleChangeSortCondition = useCallback(() => {
    if (!isActivatedSort) {
      onChangeSortCondition({
        columnId: targetColumnId,
        direction: 'asc',
      });
    } else {
      onChangeSortCondition(
        sortCondition.direction === 'asc'
          ? {
              columnId: targetColumnId,
              direction: 'desc',
            }
          : undefined
      );
    }
  }, [
    isActivatedSort,
    onChangeSortCondition,
    sortCondition?.direction,
    targetColumnId,
  ]);

  return {
    sortDirection,
    handleChangeSortCondition,
  };
};
