import { z as originalZod } from 'zod';

import { VALIDATION_ERROR_MESSAGE } from '@/constants/error';

export const z = {
  ...originalZod,
  requiredString: () =>
    z
      .string({
        required_error: VALIDATION_ERROR_MESSAGE.REQUIRED,
      })
      .min(1, VALIDATION_ERROR_MESSAGE.REQUIRED),
  requiredNumber: () =>
    z.number({
      required_error: VALIDATION_ERROR_MESSAGE.REQUIRED,
      invalid_type_error: VALIDATION_ERROR_MESSAGE.REQUIRED,
    }),
  requiredBoolean: () =>
    z.boolean({
      required_error: VALIDATION_ERROR_MESSAGE.REQUIRED,
    }),
  customEmail: () =>
    z.string().regex(
      // NOTE: https://www.w3.org/TR/2012/WD-html-markup-20121025/input.email.html#form.data.emailaddress_xref2
      /^(?:[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*)?$/,
      VALIDATION_ERROR_MESSAGE.EMAIL
    ),
  file: () =>
    z.object(
      { name: z.requiredString(), url: z.requiredString() },
      {
        required_error: VALIDATION_ERROR_MESSAGE.REQUIRED,
        invalid_type_error: VALIDATION_ERROR_MESSAGE.REQUIRED,
      }
    ),
};

/** 電話番号などで数字のみを判定する用の正規表現パターン */
export const NUMBER_ONLY_PATTERN = /^\d+$/;
