// eslint-disable-next-line @typescript-eslint/no-var-requires
const chakraUI = require('@chakra-ui/react');

module.exports = {
  // https://www.figma.com/file/3grbAtPwU6JtJiNFeswoEZ/Chakra-UI-Figma-Kit-For-Koubo-BPO?node-id=1%3A13
  colors: {
    ...chakraUI.theme.colors,
    black: '#313532',
    primary: {
      50: '#E0F5E7',
      100: '#A4E5B8',
      200: '#77D996',
      300: '#51CE78',
      400: '#36BF61',
      500: '#2EA353',
      600: '#268745',
      700: '#207039',
      800: '#1B5D2F',
      900: '#164D27',
    },
    gray: {
      ...chakraUI.theme.colors.gray,
      50: '#F7FAF8',
      100: '#F3F7F4',
      200: '#EDF3EF',
      300: '#DEE7E1',
      400: '#C5D3CA',
      500: '#A9B7AE',
      600: '#6D7E73',
      700: '#313532',
      800: '#252726',
      900: '#191A19',
    },
    yellow: {
      ...chakraUI.theme.colors.yellow,
      300: '#FFDC4F',
    },
    green: {
      ...chakraUI.theme.colors.green,
      50: '#E9F7E4',
      500: '#53B231',
    },

    lp: {
      green: {
        400: '#15BC4A',
        500: '#119C3D',
      },
      greenAlpha: 'rgba(10, 199, 99, 0.7)',
      darkGreen: 'rgba(66, 95, 88, 1)',
      bgYellow: 'rgba(247, 255, 190, 1)',
      bgGreen: 'rgba(247, 253, 241, 1)',
    },
  },
  fonts: {
    heading: 'var(--font-noto-sans-jp)',
    body: 'var(--font-noto-sans-jp)',
  },
  zIndex: chakraUI.theme.zIndices,
};
