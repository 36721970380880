export const VALIDATION_ERROR_MESSAGE = {
  REQUIRED: '必須です',
  INPUT: '入力してください',
  SELECT: '選択してください',
  NO_EMPTY: '1つ以上選択してください',
  INT: '整数を入力してください',
  NON_NEGATIVE: '0以上を入力してください',
  MAX_STRING_LENGTH: (max: number) => `${max}字以下で入力してください`,
  MIN_STRING_LENGTH: (min: number) => `${min}字以上で入力してください`,
  MAX_OPTIONS_COUNT: (max: number) => `${max}個以下で設定してください`,
  MAX_VALUE: (max: number) => `${max}以下を入力してください`,
  MIN_VALUE: (min: number) => `${min}以上を入力してください`,
  EMAIL: '不正なメールアドレスの形式です',
  SLUG: '半角英数字とハイフンのみ入力してください',
  STRING_AND_NUMBER: '半角英数字のみ入力してください',
  KANA: '全角カタカナで入力してください',
  URL: '不正なURL形式です',
  HTML: '不正なHTML形式です',
  ZIP_CODE: '7桁の半角数字を入力してください',
  FILE_FORMAT: '許可されていないファイルフォーマットです',
  FILE_SIZE: (max: number) => `ファイルサイズが${max}MBを超えています`,
  START_END_DATE_RELATION: '終了日は開始日より前の日付にしてください',
  MIN_MAX_RELATION: '最大値は最小値より大きな値にしてください',
  ONLY_NUMBER: '半角数字のみ入力してください',
} as const;

export const API_ERROR_MESSAGE = {
  UNKNOWN: 'エラーが発生しました。時間を置いてから再度お試しください',
};
